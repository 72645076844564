exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licence-js": () => import("./../../../src/pages/licence.js" /* webpackChunkName: "component---src-pages-licence-js" */),
  "component---src-pages-paymenterror-js": () => import("./../../../src/pages/paymenterror.js" /* webpackChunkName: "component---src-pages-paymenterror-js" */),
  "component---src-pages-paymentsuccess-js": () => import("./../../../src/pages/paymentsuccess.js" /* webpackChunkName: "component---src-pages-paymentsuccess-js" */),
  "component---src-pages-refunds-js": () => import("./../../../src/pages/refunds.js" /* webpackChunkName: "component---src-pages-refunds-js" */)
}

